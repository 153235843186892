import type {
  ComponentType,
  LazyExoticComponent,
  NamedExoticComponent,
} from "react";
import { Suspense, lazy } from "react";

export type TImport = () => Promise<{
  // NamedExoticComponent is a memo Component
  default: ComponentType | NamedExoticComponent;
}>;

interface TPreloadableComponent extends LazyExoticComponent<ComponentType> {
  preload?: TImport;
}

/** @SR
 * Make pages loadable to take advantage of code-splitting with lazy and Suspense
 * Also allow preloading on hover
 *
 * Question: What's the advantage of using react-loadable instead??
 */
// TODO: Fix missing return type error
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Loadable = (importStatement: TImport) => {
  const Component: TPreloadableComponent = lazy(importStatement);

  // Cannot call useTranslation at this stage, just return ... as fallback
  return function ComponentLoader(props: Record<string, unknown>) {
    return (
      <Suspense fallback={null}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
      </Suspense>
    );
  };
};

export default Loadable;
