// Note: Need these triple-slash references to the @types packages until React
// concurrent becomes stable.
/* eslint-disable spaced-comment */
/// <reference types="react-dom/experimental" />
/// <reference types="react/experimental" />
// This one is needed to avoid error "property whyDidYouRender does not exist on FC"
/// <reference types="@welldone-software/why-did-you-render" />
/* eslint-enable spaced-comment */

import "./utilities/wdyr";

import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Suspense, useEffect, useMemo, useState } from "react";

import ErrorBoundary from "./components/ErrorBoundary";
import Loadable from "./components/Loadable";

// Set up our apollo-client to point at the server we created
// this can be local or a remote endpoint
/**
 * Render our app
 * - We wrap the whole app with ApolloProvider, so any component in the app can
 *    make GraphqL requests. Our provider needs the client we created above,
 *    so we pass it as a prop
 */

const Main = (): JSX.Element => {
  const [i18nLoaded, setI18nLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      const i18n = await import("./utilities/i18n");
      i18n.initI18n();
      setI18nLoaded(true);
    })();
  }, []);

  const ApolloPersistentProvider = useMemo(
    () => Loadable(() => import("./components/ApolloPersistentProvider")),
    []
  );
  const App = useMemo(() => Loadable(() => import("./App")), []);

  return (
    // Accessibility review: https://developers.google.com/web/fundamentals/accessibility/how-to-review
    <Router>
      {/* eslint i18next/no-literal-string: 0 */}
      <ErrorBoundary fallback={<h2>Could not mount the application</h2>}>
        {i18nLoaded && ApolloPersistentProvider && App ? (
          <Suspense fallback={<h2>...</h2>}>
            <ApolloPersistentProvider>
              <App />
            </ApolloPersistentProvider>
          </Suspense>
        ) : null}
      </ErrorBoundary>
    </Router>
  );
};
ReactDOM.render(<Main />, document.getElementById("root"));
// Let's go Concurrent mode !
// ReactDOM.unstable_createRoot(
//  document.getElementById("root") as HTMLElement
// ).render(<Main />);

// grand-stack-starter uses a registerServiceWorker to serve assets from local cache.
// Do we really need that?
// https://github.com/grand-stack/grand-stack-starter/blob/master/ui-react/src/registerServiceWorker.js
