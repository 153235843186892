/* eslint-disable */
// TODO: rewrite an eslint-compliant Boundary component
import { Component, ErrorInfo } from "react";
import { Navigate } from "react-router-dom";

interface Props {
  fallback?: JSX.Element;
}

interface TState {
  hasError: boolean;
  error: any;
  redirect?: boolean;
}

class ErrorBoundary extends Component<Props> {
  state: TState = { hasError: false, error: null, redirect: false };

  static getDerivedStateFromError(error: any): TState {
    return {
      hasError: true,
      error,
    };
  }

  static componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  public componentDidUpdate() {
    if (this.state.hasError) {
      setTimeout(() => this.setState({ redirect: true }), 5000);
    }
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to="/" />;
    }

    if (this.state.hasError && this.props.fallback) {
      return this.props.fallback;
    }
    if (this.state.hasError) {
      return <h1>There was an error with this listing.</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
